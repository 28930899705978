const routes = [{
        path: '/',
        name: 'index',
        title: '首页',
        component: () => import('@/pages/index/index.vue'),
    },
    {
        path: '/collection',
        name: 'collection',
        title: '主题',
        component: () => import('@/pages/collection/index.vue'),
    },
    {
        path: '/collection/details',
        name: 'collectionDetails',
        title: '主题详情',
        component: () => import('@/pages/collection-details/index.vue'),
    },
    {
        path: '/product',
        name: 'product',
        title: '商品',
        component: () => import('@/pages/product/index.vue'),
    },
    {
        path: '/product/details',
        name: 'productDetails',
        title: '商品详情',
        component: () => import('@/pages/product-details/index.vue'),
    },
    {
        path: '/contact',
        name: 'contact',
        title: '联系我们',
        component: () => import('@/pages/contact/index.vue'),
    }
]
export default routes