import _request from '@/api/apiRequest';

import { getImageUrl } from '@/common/scripts/utils';

/**
 * @description 获取系列列表
 */
export function getCollectionList(data) {
    return _request.post('/collection/page', data);
}
/**
 * @description 获取产品集详情
 */
export function getCollectionInfo(data) {
    return _request.post('/collection/detail', data);
}

/**
 * @description 获取系列封面列表
 */
export function getCollectionCoverList(data) {
    return _request.post('/serie/page', data);
}

/**
 * @description 获取商品列表
 */
export function getProductList(data) {
    return _request.post('/product/page', data);
}

/**
 * @description 获取商品详情
 */
export function getProductDetails(data) {
    return _request.post('/product/detail', data);
}

/**
 * @description 获取网点配置详情
 */
export function getContactConfig(data) {
    return _request.post('/config/contact', data);
}


/**
 * @description 获取网点配置详情
 */
export function getHomeConfig(data) {
    return _request.post('/config/home', data);
}

export function isMobile() {
   var userAgentInfo = navigator.userAgent;

   var mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];

   var mobile_flag = false;
   
 	//根据userAgent判断是否是手机
    for (var v = 0; v < mobileAgents.length; v++) {
       if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
             mobile_flag = true;
             break;
       }
    }
    var screen_width = window.screen.width;
    var screen_height = window.screen.height;

   //根据屏幕分辨率判断是否是手机
   if (screen_width > 325 && screen_height < 750) {
       mobile_flag = true;
   }

   return mobile_flag;
}


export function getUrlRelativePath(url)
{
	var arrUrl = url.split("//");

	var start = arrUrl[1].indexOf("/");
	var relUrl = arrUrl[1].substring(start);//stop省略，截取从start开始到结尾的所有字符
	return relUrl;
}